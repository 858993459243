<template>
  <div class="container-fluid padding-top-layout padding-right-layout padding-left-layout">
    <div class="list-data-header">
      <page-title :title="titlePage" />
      <common-button
        class="submit-register-btn"
        :label="$t('supplier.button_send_data')"
        :disabled="false"
        type="colored"
        @action="onHandleActionTable()"
        v-ripple="false"
        id="submit-register-btn"
      />
    </div>
    <div class="wrap new-register-supplier">
      <div
        class="category-table table-supplier custom-table"
        :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
      >
        <data-table
          :data-source="supplierGrid"
          :grid-columns="gridColumns"
          :init-grid="initializeGrid"
          :rowFocus="1"
          :showFocus="false"
          :isShowToolbar="false"
          :isResizeCustome="false"
          @changeFullScreen="onChangeFullScreen"
          :isEmptySource="isEmptySource"
          :labelEmpty="messageLabelEmpty"
          :allowRightClick="true"
          @updateAutoFill="handleUpdateAutoFill"
        />
      </div>
    </div>
    <dialog-popup
      :dialog="dialogPopup"
      :message="$t('supplier.description_comfirm_send_data')"
      @submit="() => submitDataSupplier()"
      @close="dialogPopup = false"
    />
    <EvidenceStoragePopup
      :dialog.sync="openDialogEvidenceStorage"
      :typeForm="typeFormEvidenceStorage"
      :descriptionScope="descriptionScope"
      :evidences="evidences"
      @onSubmitSuccess="handleSubmitAttachFile"
      @onDelete="handleDeleteAttachFile"
    ></EvidenceStoragePopup>
    <notification-popup :dialog="alertNotify" :message="messageNotification" @submit="alertNotify = false" />
    <!-- <question-popup
      :dialog="dialogQuestion"
      :confirm="true"
      :message="messageQuestion"
      :confirmText="confirmQuestionText"
      :closeText="closeText"
      :isCloneTemplate="isCloneTemplate"
      :isSaveTemplateButton="isSaveTemplateButton"
      @close="closeQuestionPopUp()"
      classes="register"
      @submit="submitAction()"
    /> -->
    <notification-popup
      :dialog="dialogDeleteDataApproval"
      :message="messageDialogDeleteDataApproval"
      @submit="dialogDeleteDataApproval = false"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { CollectionView } from '@mescius/wijmo';
import _ from 'lodash';
import DataTable from '@/components/category/data-table';
import { formatDateTime } from '@/utils/datetimeFormat';
import { ROUTES } from '@/router/constants';
import { KEYS_CODE } from '@/constants/keyboard';
import * as wjGrid from '@mescius/wijmo.grid';
import { AutoComplete } from '@mescius/wijmo.input';
import PageTitle from '@/components/pageTitle/pageTitle';
import { CellMaker } from '@mescius/wijmo.grid.cellmaker';
import { BLANK_ID } from '@/constants/registerData';
import { emptyPromise } from '@/concerns/registerData/wijmo.helper';
import DialogPopup from '@/components/dialogs/question-popup';
import NotificationPopup from '@/components/dialogs/notification-popup.vue';
import { getColumnIndexByBinding } from '@/concerns/utils/filter-data';
import CommonButton from '@/components/utils/button.vue';
import { formatValue, $_helper_isNumberType} from '@/concerns/newRegisterData/wijmo.helper';
import { addThoundSandComma } from '@/utils/convertNumber';
import EvidenceStoragePopup from '@/views/supplier/EvidenceStorage/index.vue';
import { submitDataSupplierAction } from '@/api/supplier';
import { infoFormPrimaryDataApi } from '@/api/supplier';
import { getErrorMessge } from '@/utils/messageHandle';
import { MESSAGE_NOT_NULL, GICS_PULLDOWN } from '@/constants/registerData';
import throttle from 'lodash/throttle';
import { convertNestedFullWidthToHalfWidth } from '@/utils/convertHalfWidth';
import { getWidthByTextContent } from '@/utils/calcTextWidth';
import { validateMaximumCharactorOnlyFacility } from '@/utils/validate';
import { getWidthOfAttachedButton } from '@/utils/multiLanguage';
import { getListGicsByYear } from '@/utils/registerData';

export default {
  name: 'ListData',
  components: {
    DataTable,
    PageTitle,
    DialogPopup,
    NotificationPopup,
    CommonButton,
    EvidenceStoragePopup,
  },
  props: {},
  data() {
    return {
      items: [
        {
          text: this.$t('supplier.title_primary_submission_form'),
          disabled: false,
          href: ROUTES.SUPPLIER,
        },
        {
          text: this.$t('supplier.hyperlink_emissions_registration'),
          disabled: true,
          href: ROUTES.REGISTER_PRIMARY_DATA,
        },
      ],
      flexGrid: null,
      listHeaders: [],
      supplierGrid: null,
      gridColumns: [],
      showTooltip: false,
      isEmptySource: true,
      itemLoadMoreNumber: 3000,
      dataOriginal: [],
      dataCurrent: [],
      dialogPopup: false,
      alertNotify: false,
      isHasDataTable: false,
      evidences: {},
      isChangeEvidence: false,
      openDialogEvidenceStorage: false,
      typeFormEvidenceStorage: 'view',
      onlyViewEvidence: false,
      selectedRow: null,
      dialogQuestion: false,
      dialogDeleteDataApproval: false,
      messageDialogDeleteDataApproval: '',
      pattern: {},
      messageNotification: '',
      infoFormPrimaryData: {},
      unitSource: '',
      max128Charactor: ['transportation', 'transportation_facilities', 'business_trip_type', 'memo_1'],
      gicsList: []
    };
  },

  async mounted() {
    await Promise.all([this.handleGetGicsList(), this.updateBreadCrumb(this.items), this.defineDataTable()]);
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    ...mapState('registerData', ['isFullScreen']),
    ...mapState('userData', ['currentUser', 'dbSourceId']),
    ...mapState('supplier', ['supplierData', 'dataInfo']),
    titlePage() {
      return this.$t('supplier.label_sub_emissions',{
          selectedYear: this.dataInfo.year,
          selectedMonth: this.dataInfo.month,
        })
      ;
    },
    messageLabelEmpty() {
      return this.$t('idea_db_source.description_source_details_empty');
    },
    descriptionScope() {
      return {
        scope: this.infoFormPrimaryData.scope,
        category: this.infoFormPrimaryData.category,
        year: this.dataInfo.year,
        month: this.dataInfo.month,
        pattern_id: this.infoFormPrimaryData.pattern_id,
        onlyView: this.onlyViewEvidence,
        method: this.getMethodName === this.$t('register_data.button_please_select') ? null : this.getMethodName,
      };
    },
    getGicValue() {
      return this.infoFormPrimaryData?.classify_gics
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingTable']),
    ...mapActions('supplier', ['setStatusDataSupplier']),

    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return '';
    },
    dateTimeFormat(dateTime) {
      return formatDateTime(dateTime);
    },
    async getInfoFormPrimaryDataApi() {
      await infoFormPrimaryDataApi(this.$route?.query?.primary_uuid)
        .then((res) => {
          this.infoFormPrimaryData = res.data;
        })
        .catch(() => {
          // redirect to home if role not suplier & 404 if role suplier
          const ROLE_SUPLIER = 6;
          if (res.user.role_id === ROLE_SUPLIER) {
            this.$router.push('/404');
          } else {
            this.$router.push({ path: ROUTES.TYPE_SELECTION });
          }
        });
    },
    async defineDataTable() {
      await this.getInfoFormPrimaryDataApi();
      this.pattern = this.getInfoPatternCurrent();
      this.unitSource = this.getUnitSource();
      this.supplierGrid = new CollectionView([], { trackChanges: true });
      this.defineTable();
      this.isEmptySource = false;
      this.dataOriginal = JSON.parse(JSON.stringify(this.supplierGrid.items));
      this.actionUpdateIsLoadingTable(false);
    },
    handleEvent() {
      const cellInputInView = this.pattern.cellInputInView;
      this.supplierGrid?.collectionChanged.addHandler(
        throttle(() => {
          this.supplierGrid?.itemsAdded?.forEach((dataTableItemAdded) => {
            if (
              cellInputInView.every(
                (field) =>
                  dataTableItemAdded[field] === null ||
                  dataTableItemAdded[field] === '' ||
                  dataTableItemAdded[field] === undefined,
              )
            ) {
              return;
            }
            // format number in itemAdd when pasted (not blank row)
            cellInputInView.forEach((key) => {
              const { scope, category, pattern_id } = this.infoFormPrimaryData;
              let convertValue = convertNestedFullWidthToHalfWidth(dataTableItemAdded[key]);
              let numberRange = formatValue(convertValue)?.toString().includes('-') ? 26 : 25;
              if(['fuel_efficiency','loading_data_rate'].includes(key)) {
                numberRange = formatValue(convertValue)?.includes('-') ? 11 : 10;
              }
              let isTransportationKey = key === 'transportation' || key === 'transportation_facilities';
              let isTransportationS3C6 =
                scope == 3 && category == 6 && (pattern_id == 1 || pattern_id == 4) && isTransportationKey;
              let isBusinessTrip = scope == 3 && category == 6 && pattern_id == 6 && key === 'business_trip_type';
              let isProductNameAndMemo1 = scope == 3 && category == 11 && (key === 'product_name' || key === 'memo_1');
              let isYearGHG = scope == 3 && category == 15 && [1, 2, 3, 4].includes(pattern_id) && key === 'ie_year';
              let emissionsFactor = scope == 3 && category == 15 && [5,6,7,8,9,10,11].includes(pattern_id) && key === 'emissions_factor';
              let isTransportationS3C7 =
                scope == 3 && category == 7 && (pattern_id == 1 || pattern_id == 4) && isTransportationKey;
              let isOtherCase =
                !isTransportationS3C6 &&
                !isBusinessTrip &&
                !isTransportationS3C7 &&
                !isTransportationKey &&
                !isProductNameAndMemo1 &&
                !isYearGHG &&
                !emissionsFactor;
              if (isTransportationS3C6 || isBusinessTrip || isTransportationS3C7 || isOtherCase || isProductNameAndMemo1 ) {
                dataTableItemAdded[key] = convertValue;
                dataTableItemAdded['unit'] = convertValue ? this.unitSource : '';
              }
              if (isYearGHG) {
                const isYearValid = convertValue && $_helper_isNumberType(convertValue) && formatValue(convertValue).length === 4;
                dataTableItemAdded[key] = isYearValid ? convertValue : '';
                dataTableItemAdded['unit'] = isYearValid ? this.unitSource : '';
              }
              if (isOtherCase && dataTableItemAdded[key]) {
                const isNumber = $_helper_isNumberType(convertValue);
                const newValue = isNumber ? addThoundSandComma(formatValue(convertValue)?.substring(0, numberRange)) : null;
                dataTableItemAdded[key] = newValue;
                dataTableItemAdded['unit'] = newValue ? this.unitSource : '';
                if (scope == 3 && category == 15 && [5,6,7,8,9,10].includes(pattern_id)) {
                  const type_data = this.infoFormPrimaryData?.type_data;
                  const isOtherCaseIgnore = [5, 6, 7].includes(pattern_id) && (type_data.id === 1 || type_data.id === 2); 
                  if (!dataTableItemAdded.emissions_factor && !isOtherCaseIgnore) {
                    const gicsItem = this.gicsList.find(gics => gics.key === this.infoFormPrimaryData.classify_gics); 
                    dataTableItemAdded['emissions_factor'] = addThoundSandComma(formatValue(gicsItem?.autoField)?.substring(0, 25));
                  }
                }
              }
              const everyNumberInRowNull = cellInputInView.every((field) => {
                const value = dataTableItemAdded[field];
                return value === null || value === '' || value === undefined;
              });
              dataTableItemAdded['unit'] = everyNumberInRowNull ? '' : this.unitSource,
              dataTableItemAdded['id'] = BLANK_ID;
            });
          });
        }),
      );
    },
    handleUpdateAutoFill(dataTable) {
      const cellInputInView = this.pattern.cellInputInView;
      const everyNumberInRowNull = cellInputInView.every((field) => {
        return dataTable[field] === null || dataTable[field] === '' || dataTable[field] === undefined;
      });
      dataTable['unit'] = everyNumberInRowNull ? '' : this.unitSource;
    },
    isYearValid(year) {
      if (!$_helper_isNumberType(year) || formatValue(year)?.length !== 4) {
        return false;
      }
      if (Number(year) > 9999 || Number(year) < 0) {
        return false;
      }
      return !year.includes('.');
    },
    autoBindingData(bindingData) {
      const { row, col, flexGrid, column } = bindingData;
      const cellData = flexGrid.getCellData(row, col, false);
      const binding = column.binding;
      const cellInputInView = this.pattern.cellInputInView;
      const view = flexGrid.collectionView;
      const source = view.sourceCollection;
      const currentItem = source[row];
      const { scope, category, pattern_id } = this.infoFormPrimaryData;
      if (cellInputInView.includes(binding)) {
        const convertValue = convertNestedFullWidthToHalfWidth(cellData);
        const { scope, category, pattern_id } = this.infoFormPrimaryData;
        let numberRange = formatValue(convertValue)?.toString().includes('-') ? 26 : 25;
        if(['fuel_efficiency','loading_data_rate'].includes(binding)) {
          numberRange = formatValue(convertValue)?.includes('-') ? 11 : 10;
        }
        if (
          (scope == 3 && category == 6 && pattern_id == 1 && binding === 'transportation') ||
          (scope == 3 && category == 6 && pattern_id == 4 && binding === 'transportation_facilities') ||
          (scope == 3 && category == 6 && pattern_id == 6 && binding === 'business_trip_type') ||
          (scope == 3 && category == 7 && pattern_id == 1 && binding === 'transportation') ||
          (scope == 3 && category == 7 && pattern_id == 4 && binding === 'transportation_facilities') || 
          (scope == 3 && category == 11 && (binding === 'product_name' || binding === 'memo_1'))
        ) { 
          flexGrid.setCellData(row, col, convertValue, false, true);
        } else if (scope == 3 && category == 15 && [1, 2, 3, 4].includes(pattern_id) && binding === 'ie_year') {
          flexGrid.setCellData(row, col, this.isYearValid(convertValue) ? convertValue : '', false, true);
        } else {
          const isNumber = $_helper_isNumberType(convertValue);
          const newValue = isNumber ? addThoundSandComma(formatValue(convertValue)?.substring(0, numberRange)) : null;
          flexGrid.setCellData(row, col, newValue, false, true);
        }
      }
      const everyNumberInRowNull = cellInputInView.every((field) => {
        const value = this.supplierGrid.sourceCollection[row][field];
        return value === null || value === '' || value === undefined;
      });
      flexGrid.setCellData(
        row,
        getColumnIndexByBinding(flexGrid, 'unit'),
        everyNumberInRowNull ? '' : this.unitSource,
        false,
        true,
      );
      if (scope == 3 && category == 15 && [5,6,7,8,9,10].includes(pattern_id)) {
        const type_data = this.infoFormPrimaryData?.type_data;
        const isOtherCaseIgnore = [5, 6, 7].includes(pattern_id) && (type_data.id === 1 || type_data.id === 2); 
        if (!currentItem.emissions_factor && !isOtherCaseIgnore) {
          const gicsItem = this.gicsList.find(gics => gics.key === this.infoFormPrimaryData.classify_gics); 
          flexGrid.setCellData(
            row,
            getColumnIndexByBinding(flexGrid, 'emissions_factor'),
            everyNumberInRowNull ? '' : addThoundSandComma(formatValue(gicsItem?.autoField)?.substring(0, 25)),
            false,
            true,
          );
        }
      }
    },
    initializeGrid(flexgrid) {
      this.flexGrid = flexgrid;
      document.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW].includes(
            event.keyCode,
          )
        ) {
          event.preventDefault();
        }
      });
      flexgrid.pasted.addHandler((flexGrid, event) => {
        const { col, col2, row, row2 } = event.range;
        for (let colIndex = col; colIndex <= col2; colIndex++) {
          for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
            let column = flexGrid.columns[colIndex];
            const bindingData = { row: rowIndex, col: colIndex, flexGrid, column };
            this.autoBindingData(bindingData);
          }
        }
        setTimeout(() => {
          this.actionUpdateIsLoadingTable(false);
        }, 0);
      });
      flexgrid.cellEditEnded.addHandler((flexGrid, event) => {
        let column = flexGrid.columns[event.col];
        const { row, col } = event.range;
        const bindingData = { row, col, flexGrid, column };
        this.autoBindingData(bindingData);
      });
      flexgrid.hostElement?.addEventListener(
        'keydown',
        (event) => {
          if (event.keyCode === KEYS_CODE.ENTER && this.isApproval) {
            event.stopImmediatePropagation();
          }
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.KEY_V) {
              this.actionUpdateIsLoadingTable(true);
              const { row, row2 } = flexgrid.selection;
              const isSingleRowPasted = row2 === row;
              if (isSingleRowPasted) {
                setTimeout(() => {
                  this.actionUpdateIsLoadingTable(false);
                }, 300);
              }
            }
          }
        },
        false,
      );
      flexgrid.beginningEdit.addHandler((s, e) => {
        const column = s.columns[e.col];
        const { row, col } = e.range
        if(!this.max128Charactor.includes(column.binding)){
          const cellData = s.getCellData(row, col, false)
          s.setCellData(row, col, formatValue(cellData), false, true);
        }
      });
      flexgrid.formatItem.addHandler((s, e) => {
        if(e.panel == s.cells && e.panel.columns[e.col].binding === 'attach_file') {
          if (e.cell.querySelector('button') && e.cell.querySelector('img')) {
            const img = e.cell.querySelector('img');
            e.cell.querySelector('button').addEventListener('mouseenter', function () {
              if (img) {
                img.setAttribute('src', require('@/assets/icons/file_attached_active.svg'));
              }
            });
          
            e.cell.querySelector('button').addEventListener('mouseleave', function () {
              if (img) {
                img.setAttribute('src', require('@/assets/icons/file_attached.svg'));
              }
            });
          }
        }
      })
    },
    defineTable() {
      this.gridColumns = this.getGridColumns();
      this.flexGrid.columnGroups = this.getGridColumns();
      this.addBlankItemsToView(10);
      this.supplierGrid.currentItem = null;
      this.handleEvent();
      this.isEmptySource = this.supplierGrid?.items?.length <= 0;
      this.$nextTick(() => {
        this.scrollToTop();
      });
    },
    getGridColumns() {
      const { scope, category, pattern_id } = this.infoFormPrimaryData;
      let headersBindingData = this.pattern.binding
        ? this.pattern.binding
        : [{ header: this.$t('supplier.table_emission'), binding: this.pattern.cellInputInView[0] }];
      const headersList = headersBindingData.map((h) => {
        if(h.binding === 'product_name' && scope == 3 && category == 11) {
          const productName = this.infoFormPrimaryData?.product_name;
          const productMapping = (productName?.split(/[、,]/) || []).map(item => item.trim()).filter(item => item !== '');

          return ({
              header: h.header,
              binding: h.binding,
              minWidth: getWidthByTextContent(this.$t('56_pattern.table_product_name')),
              maxWidth: 1200,
              allowSorting: false,
              isRequired: false,
              wordWrap: true,
              dataMap: new wjGrid.DataMap(productMapping),
              editor: new AutoComplete(document.createElement('div'), {
                placeholder: this.$t('56_pattern.placeholder'),
                itemsSource: productMapping,
                maxItems: 1000,
                minLength: 1,
                selectedIndex: -1,
              }),
            })
        }
        return  ({
          header: h.header,
          binding: h.binding,
          minWidth: getWidthByTextContent(h.header),
          maxWidth: 1200,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          align: h.isInputText ? 'left' : 'right',
        })
      });
      return [
        {
          header: '#',
          binding: 'id',
          allowSorting: false,
          isReadOnly: true,
          visible: false,
        },
        {
          header: this.$t('supplier.table_discharge'),
          align: 'center',
          columns: [
            ...headersList,
            {
              header: this.$t('supplier.table_unit'),
              binding: 'unit',
              minWidth: 120,
              maxWidth: 980,
              allowSorting: false,
              isReadOnly: true,
              wordWrap: true,
              isRequired: false,
              cssClassAll: 'single-row',
            },
          ],
        },
        {
          header: this.$t('56_pattern.table_attach_file'),
          binding: 'attach_file',
          cssClass: 'auto-increment',
          minWidth: getWidthOfAttachedButton(this.$i18n.locale)+ 10,
          maxWidth: getWidthOfAttachedButton(this.$i18n.locale)+ 10,
          allowSorting: false,
          isRequired: false,
          cssClassAll: 'btn-db attach-file hide-filter',
          cellTemplate: (ctx, el) => {
            let text = this.$t('56_pattern.button_attach_file');
            if (ctx.item?.attach_file) {
              text = `<div class="file-attached-container">
                <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
              </div>`
            }
            const button = CellMaker.makeButton({
              text: text,
              click: (e, context) => this.handleOpenAttachFile(e, context),
            });
            return button(ctx, el);
          },
        },
      ];
    },
    handleOpenAttachFile(e, context) {
      this.evidences = {};
      this.selectedRow = context.row.index;

      let evidenceData = context?.item?.evidence;
      if (typeof evidenceData === 'string') {
        evidenceData = JSON.parse(evidenceData);
      }
      if (this.isViewOnlyAttachFile) {
        if (evidenceData && evidenceData.file_name) {
          this.evidences.data = evidenceData;
          this.typeFormEvidenceStorage = 'view';
          this.onlyViewEvidence = true;
          this.openDialogEvidenceStorage = true;
        }
        return;
      }
      if (evidenceData && evidenceData.file_name) {
        this.evidences.data = evidenceData;
        this.typeFormEvidenceStorage = 'view';
      } else {
        this.typeFormEvidenceStorage = 'create';
      }
      this.openDialogEvidenceStorage = true;
    },
    handleSubmitAttachFile(dataEvidence) {
      let evidenceDataInCellTable = this.supplierGrid.sourceCollection[this.selectedRow]?.evidence;
      dataEvidence = {
        ...evidenceDataInCellTable,
        ...dataEvidence,
        created_at: evidenceDataInCellTable ? evidenceDataInCellTable.created_at : dataEvidence.created_at, // TODO: always get the first created date of the record
      };
      const rowIndex = this.selectedRow;
      this.updateFlexgridCells(rowIndex, {
        attach_file: dataEvidence?.file_name,
      });
      if (this.supplierGrid.sourceCollection[rowIndex] && dataEvidence?.file_name) {
        this.supplierGrid.sourceCollection[rowIndex].evidence = dataEvidence;
        this.supplierGrid.sourceCollection[rowIndex].attach_file = dataEvidence.file_name;
      }
    },
    handleDeleteAttachFile() {
      this.evidences = {};
      this.updateFlexgridCells(this.selectedRow, {
        attach_file: null,
      });
      this.supplierGrid.sourceCollection[this.selectedRow].evidence = null;
      this.supplierGrid.sourceCollection[this.selectedRow].attach_file = null;
    },
    updateFlexgridCells(rowIndex, cellData) {
      if (!this.supplierGrid.sourceCollection[rowIndex]) {
        this.addBlankItemsToView(1);
      }
      for (const columnName in cellData) {
        const colIndex = getColumnIndexByBinding(this.flexGrid, columnName);
        if (colIndex >= 0) {
          this.flexGrid.setCellData(rowIndex, colIndex, cellData[columnName], false, true);
        }
      }
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    blankData(clientRowId) {
      const listValuesNull = {};
      this.pattern.cellInputInView.forEach((field) => {
        listValuesNull[field] = null;
      });
      return {
        id: BLANK_ID,
        unit: null,
        value: null,
        attach_file: null,
        ...listValuesNull,
      };
    },
    addBlankItemsToView(count) {
      const lastClientId = 0;
      for (let index = 1; index <= count; index++) {
        this.supplierGrid.addNew(this.blankData(lastClientId + index));
      }

      this.supplierGrid.commitNew();
      this.supplierGrid.clearChanges();
    },
    closePopup() {
      this.commentPopup = false;
      this.historyPopup = false;
    },
    getUnitSource() {
      const scope = parseInt(this.infoFormPrimaryData.scope);
      const category = parseInt(this.infoFormPrimaryData.category);
      const pattern_id = parseInt(this.infoFormPrimaryData.pattern_id);
      if (
        (scope == 3 && category == 14 && pattern_id == 1) ||
        (scope === 3 && category === 15 && [1, 2 , 3, 4, 5, 6, 7, 8, 9, 10, 11].includes(pattern_id))
      )
        return 't-CO2';
      const { db_customize, db_master, db_source_detail } = this.infoFormPrimaryData;
      return db_customize?.unit || db_master?.unit || db_source_detail[0]?.unit || null;
    },
    getPatternPCAFOrGHG() {
      let pattern = {};
      const scope = parseInt(this.infoFormPrimaryData.scope);
      const category = parseInt(this.infoFormPrimaryData.category);
      const pattern_id = parseInt(this.infoFormPrimaryData.pattern_id);
      const type_data = this.infoFormPrimaryData?.type_data;
      const typePatternHasMillionYen = [5, 6]
      if (scope === 3 && category === 15) {
        if([5, 6, 7, 8, 9, 10, 11].includes(pattern_id)) {
          if ([5, 6, 7].includes(pattern_id)) {
            let headerEmissionFactor = this.$t('56_pattern.table_emissions_factor')
            if(typePatternHasMillionYen.includes(type_data.id)) {
              if(this.$i18n.locale !== 'ja') {
                headerEmissionFactor = `${this.$t('56_pattern.table_emissions_factor')} (t-CO2/${this.$t('dashboard_main.label_one_million_yen')})`
              } else {
                headerEmissionFactor = `${this.$t('56_pattern.table_emissions_factor')}（t-CO2/${this.$t('dashboard_main.label_one_million_yen')}）`
              }
            } 
            const cellInputInViewAndBinding =
            type_data.id === 1 || type_data.id === 2
                ? {
                    cellInputInView: ['value_number'],
                    binding: [{ header: type_data.name, binding: 'value_number' }],
                  }
                : {
                    cellInputInView: ['value_number', 'emissions_factor'],
                    binding: [
                      { header: type_data.name, binding: 'value_number' },
                      { header: headerEmissionFactor, binding: 'emissions_factor' },
                    ],
                  };
            pattern = {
              scope,
              category,
              pattern_id,
              ...cellInputInViewAndBinding,
              fieldsSubmit: ['value_number', 'emissions_factor'],
            };
          } else if ([8, 9].includes(pattern_id)) {
            const cellInputInViewAndBinding =
              type_data.id === 7 || type_data.id === 8
                ? {
                    cellInputInView: ['value_number_1', 'emissions_factor'],
                    binding: [
                      { header: type_data?.name, binding: 'value_number_1' },
                      { header: type_data.id === 7 ? this.$t('pcaf_update.supplier_emission_factor') : this.$t('pcaf_update.average_emission_factor'), binding: 'emissions_factor' },
                    ],
                  }
                : {
                    cellInputInView: ['value_number_1', 'value_number_2', 'emissions_factor'],
                    binding: [
                      { header: type_data?.name, binding: 'value_number_1' },
                      { header: type_data.id === 11 ? this.$t('pcaf_update.number_of_buildings') : this.$t('pcaf_update.floor_space'), binding: 'value_number_2' },
                      { header: this.$t('pcaf_update.average_emission_factor'), binding: 'emissions_factor' },
                    ],
                  };
            pattern = {
              scope,
              category,
              pattern_id,
              ...cellInputInViewAndBinding,
              fieldsSubmit: ['value_number_1', 'value_number_2', 'emissions_factor'],
            };
          } else if (pattern_id === 10) {
            let name_emissions_type_col = [16,17].includes(type_data?.id) ? this.$t('pcaf_update.estimated_travel_distance') : type_data?.name;
            let name_acreage_col = '';
            let name_emission_col = type_data.id === 12 || type_data.id === 13 ? this.$t('pcaf_update.emission_factors_by_fuel') : this.$t('pcaf_update.emission_factors_by_fuel_co2');

            if(type_data.id > 12 && type_data.id <= 15) {
              name_acreage_col = this.$t('pcaf_update.fuel_consumption_by_vehicle_model')
            } else if(type_data.id === 16) {
              name_acreage_col = this.$t('pcaf_update.fuel_consumption_by_vehicle_type')
            } else if (type_data.id === 17) {
              name_acreage_col = this.$t('pcaf_update.average_vehicle_fuel_economy')
            }

            const cellInputInViewAndBinding =
            type_data.id === 12
                ? {
                    cellInputInView: ['value_number_1', 'emissions_factor'],
                    binding: [
                      { header:name_emissions_type_col, binding: 'value_number_1' },
                      { header: name_emission_col, binding: 'emissions_factor' },
                    ],
                  }
                : {
                    cellInputInView: ['value_number_1', 'value_number_2', 'emissions_factor'],
                    binding: [
                      { header:name_emissions_type_col, binding: 'value_number_1' },
                      { header: name_acreage_col, binding: 'value_number_2' },
                      { header: name_emission_col, binding: 'emissions_factor' },
                    ],
                  };
            pattern = {
              scope,  
              category,
              pattern_id,
              ...cellInputInViewAndBinding,
              fieldsSubmit: ['value_number_1', 'value_number_2', 'emissions_factor'],
            };
          } else {
            pattern = {
              scope,
              category,
              pattern_id,
              cellInputInView: ['government_bond_emissions'],
              binding: [
                {
                  header: type_data.name,
                  binding: 'government_bond_emissions',
                },
              ],
              fieldsSubmit: ['government_bond_emissions'],
            };
          }
        }
        else if ([1,2,3,4].includes(pattern_id)) {
          if (pattern_id === 1) {
            pattern = {
              scope,
              category,
              pattern_id,
              cellInputInView: [ 'ie_year', 'ie_company_value' ],
              binding: [
                { header: this.$t('56_pattern.table_ie_company_value'), binding: 'ie_company_value' },
                { header: this.$t('56_pattern.table_ie_year'), binding: 'ie_year' },
              ]
            };
          } else if (pattern_id === 2) {
            pattern = {
              scope,
              category,
              pattern_id,
              cellInputInView: [ 'ie_year', 'ie_company_value' ],
              binding: [
                { header: this.$t('56_pattern.table_ie_company_value'), binding: 'ie_company_value' },
                { header: this.$t('56_pattern.table_ie_year'), binding: 'ie_year' },
              ]
            };
          } else if (pattern_id === 3) {
            pattern = {
              scope,
              category,
              pattern_id,
              cellInputInView: [ 'le_value', 'ie_year' ],
              binding: [
                { header: this.$t('56_pattern.table_le_value'), binding: 'le_value' },
                { header: this.$t('56_pattern.table_ie_year'), binding: 'ie_year' },
              ]
            };
          } else if (pattern_id === 4) {
            pattern = {
              scope,
              category,
              pattern_id,
              cellInputInView: [ 'ie_year', 'ie_company_value' ],
              binding: [
                { header: this.$t('56_pattern.table_ie_company_value'), binding: 'ie_company_value' },
                { header: this.$t('56_pattern.table_ie_year'), binding: 'ie_year' },
              ]            
            };
          }
        }
      }
      return pattern;
    },
    getInfoPatternCurrent() {
      const scope = parseInt(this.infoFormPrimaryData.scope);
      const category = parseInt(this.infoFormPrimaryData.category);
      const pattern_id = parseInt(this.infoFormPrimaryData.pattern_id);
      if (scope === 3 && category === 15 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].includes(pattern_id)) {
        return this.getPatternPCAFOrGHG();
      }
      const patternsSupplier = [
        { scope: 1, category: 1, pattern_id: 1, cellInputInView: ['value'] },
        {
          scope: 1,
          category: 1,
          pattern_id: 3,
          cellInputInView: ['fuel_efficiency', 'distance'],
          binding: [
            { header: this.$t('56_pattern.table_transport_distance'), binding: 'distance' },
            { header: this.$t('56_pattern.table_fuel_efficiency'), binding: 'fuel_efficiency' },
          ],
        },
        {
          scope: 1,
          category: 1,
          pattern_id: 2,
          cellInputInView: ['loading_data_maximum', 'loading_data_rate', 'value'],
          binding: [
            { header: this.$t('56_pattern.table_loading_data_maximum'), binding: 'loading_data_maximum' },
            { header: this.$t('56_pattern.table_loading_data_rate'), binding: 'loading_data_rate' },
            { header: this.$t('56_pattern.table_transport_distance'), binding: 'value' },
          ],
        },
        { scope: 1, category: 1, pattern_id: 4, cellInputInView: ['value'] },
        { scope: 2, category: 1, pattern_id: 1, cellInputInView: ['value'] },
        { scope: 3, category: 1, pattern_id: 1, cellInputInView: ['qd_value'] },
        { scope: 3, category: 3, pattern_id: 1, cellInputInView: ['energy_value'] },
        { scope: 3, category: 4, pattern_id: 4, cellInputInView: ['value'] },
        {
          scope: 3,
          category: 4,
          pattern_id: 5,
          cellInputInView: ['loading_data_maximum', 'loading_data_rate', 'distance'],
          binding: [
            { header: this.$t('56_pattern.table_loading_data_maximum'), binding: 'loading_data_maximum' },
            { header: this.$t('56_pattern.table_loading_data_rate'), binding: 'loading_data_rate' },
            { header: this.$t('56_pattern.table_transport_distance'), binding: 'distance' },
          ],
        },
        {
          scope: 3,
          category: 4,
          pattern_id: 6,
          cellInputInView: ['fuel_efficiency', 'distance'],
          binding: [
            { header: this.$t('56_pattern.table_transport_distance'), binding: 'distance' },
            { header: this.$t('56_pattern.table_fuel_efficiency'), binding: 'fuel_efficiency' },
          ],
        },
        { scope: 3, category: 4, pattern_id: 7, cellInputInView: ['value'] },
        { scope: 3, category: 4, pattern_id: 8, cellInputInView: ['value'] },
        {
          scope: 3,
          category: 4,
          pattern_id: 2,
          cellInputInView: ['energy_saving_value', 'scope12_overlap'],
          binding: [
            { header: this.$t('56_pattern.table_energy_saving_value'), binding: 'energy_saving_value' },
            { header: this.$t('56_pattern.table_scope12_overlap'), binding: 'scope12_overlap' },
          ],
        },
        { scope: 3, category: 5, pattern_id: 1, cellInputInView: ['industrial_waste_value'] },
        {
          scope: 3,
          category: 6,
          pattern_id: 1,
          cellInputInView: ['transportation', 'distance', 'passengers'],
          binding: [
            { header: this.$t('56_pattern.table_transportation'), binding: 'transportation', isInputText: true },
            { header: this.$t('56_pattern.table_category_6_distance'), binding: 'distance' },
            { header: this.$t('56_pattern.table_category_6_passenger'), binding: 'passengers' },
          ],
        },
        { scope: 3, category: 6, pattern_id: 2, cellInputInView: ['value'] },
        {
          scope: 3,
          category: 6,
          pattern_id: 3,
          cellInputInView: ['distance', 'fuel_efficiency'],
          binding: [
            { header: this.$t('56_pattern.table_transport_distance'), binding: 'distance' },
            { header: this.$t('56_pattern.table_fuel_efficiency'), binding: 'fuel_efficiency' },
          ],
        },
        {
          scope: 3,
          category: 6,
          pattern_id: 4,
          cellInputInView: ['transportation_facilities', 'travel_value'],
          binding: [
            {
              header: this.$t('56_pattern.table_transportation_facilities'),
              binding: 'transportation_facilities',
              isInputText: true,
            },
            { header: this.$t('56_pattern.table_supplier_table_travel_value'), binding: 'travel_value' },
          ],
        },
        {
          scope: 3,
          category: 6,
          pattern_id: 5,
          cellInputInView: ['nights'],
          binding: [{ header: this.$t('56_pattern.table_nights'), binding: 'nights' }],
        },
        {
          scope: 3,
          category: 6,
          pattern_id: 6,
          cellInputInView: ['business_trip_type', 'business_trip_days'],
          binding: [
            {
              header: this.$t('56_pattern.table_business_trip_type'),
              binding: 'business_trip_type',
              isInputText: true,
            },
            { header: this.$t('56_pattern.table_business_trip_days'), binding: 'business_trip_days' },
          ],
        },
        {
          scope: 3,
          category: 6,
          pattern_id: 7,
          cellInputInView: ['employees_number_value'],
          binding: [{ header: this.$t('56_pattern.table_employees_number_value'), binding: 'employees_number_value' }],
        },
        {
          scope: 3,
          category: 7,
          pattern_id: 1,
          cellInputInView: ['transportation', 'distance', 'passengers'],
          binding: [
            { header: this.$t('56_pattern.table_transportation'), binding: 'transportation', isInputText: true },
            { header: this.$t('56_pattern.table_category_7_distance'), binding: 'distance' },
            { header: this.$t('56_pattern.table_category_7_passenger'), binding: 'passengers' },
          ],
        },
        { scope: 3, category: 7, pattern_id: 2, cellInputInView: ['value'] },
        {
          scope: 3,
          category: 7,
          pattern_id: 3,
          cellInputInView: ['distance', 'fuel_efficiency'],
          binding: [
            { header: this.$t('56_pattern.table_transport_distance'), binding: 'distance' },
            { header: this.$t('56_pattern.table_fuel_efficiency'), binding: 'fuel_efficiency' },
          ],
        },
        {
          scope: 3,
          category: 7,
          pattern_id: 4,
          cellInputInView: ['transportation_facilities', 'te_value'],
          binding: [
            {
              header: this.$t('56_pattern.table_transportation_facilities'),
              binding: 'transportation_facilities',
              isInputText: true,
            },
            { header: this.$t('56_pattern.table_te_value'), binding: 'te_value' },
          ],
        },
        {
          scope: 3,
          category: 7,
          pattern_id: 5,
          cellInputInView: ['employees_number_value', 'working_days_number_value'],
          binding: [
            { header: this.$t('56_pattern.table_employees_number_value'), binding: 'employees_number_value' },
            { header: this.$t('56_pattern.table_working_days_number_value'), binding: 'working_days_number_value' },
          ],
        },
        { scope: 3, category: 9, pattern_id: 1, cellInputInView: ['value'] },
        {
          scope: 3,
          category: 9,
          pattern_id: 2,
          cellInputInView: ['loading_data_maximum', 'loading_data_rate', 'distance'],
          binding: [
            { header: this.$t('56_pattern.table_loading_data_maximum'), binding: 'loading_data_maximum' },
            { header: this.$t('56_pattern.table_loading_data_rate'), binding: 'loading_data_rate' },
            { header: this.$t('56_pattern.table_transport_distance'), binding: 'distance' },
          ],
        },
        {
          scope: 3,
          category: 9,
          pattern_id: 3,
          cellInputInView: ['fuel_efficiency', 'distance'],
          binding: [
            { header: this.$t('56_pattern.table_transport_distance'), binding: 'distance' },
            { header: this.$t('56_pattern.table_fuel_efficiency'), binding: 'fuel_efficiency' },
          ],
        },
        { scope: 3, category: 9, pattern_id: 4, cellInputInView: ['value'] },
        { scope: 3, category: 9, pattern_id: 5, cellInputInView: ['value'] },
        {
          scope: 3,
          category: 10,
          pattern_id: 1,
          cellInputInView: ['sales_volume_value'],
          binding: [{ header: this.$t('56_pattern.table_sales_volume_value'), binding: 'sales_volume_value' }],
        },
        {
          scope: 3,
          category: 11,
          pattern_id: 1,
          cellInputInView: ['product_name', 'memo_1', 'times_use', 'sold_number'],
          binding: [
            
            { header: this.$t('56_pattern.table_product_name'), binding: 'product_name'},
            { header: this.$t('56_pattern.table_remarks'), binding: 'memo_1', isInputText: true },
            { header: this.$t('56_pattern.table_times_use'), binding: 'times_use' },
            { header: this.$t('56_pattern.table_sold_number'), binding: 'sold_number' },
          ],
        },
        {
          scope: 3,
          category: 11,
          pattern_id: 2,
          cellInputInView: ['product_name', 'memo_1', 'feedstock_value'],
          binding: [
            { header: this.$t('56_pattern.table_product_name'), binding: 'product_name' },
            { header: this.$t('56_pattern.table_remarks'), binding: 'memo_1', isInputText: true },
            { header: this.$t('56_pattern.table_feedstock_value'), binding: 'feedstock_value' },
          ],
        },
        {
          scope: 3,
          category: 11,
          pattern_id: 3,
          cellInputInView: ['product_name', 'memo_1', 'ghg_content_value', 'total_products_sold', 'ghg_rate'],
          binding: [
            { header: this.$t('56_pattern.table_product_name'), binding: 'product_name' },
            { header: this.$t('56_pattern.table_remarks'), binding: 'memo_1', isInputText: true },
            { header: this.$t('56_pattern.table_ghg_content_value'), binding: 'ghg_content_value' },
            { header: this.$t('56_pattern.table_total_products_sold'), binding: 'total_products_sold' },
            { header: this.$t('56_pattern.table_ghg_rate'), binding: 'ghg_rate' },
          ],
        },
        {
          scope: 3,
          category: 11,
          pattern_id: 4,
          cellInputInView: ['product_name', 'memo_1', 'times_use', 'sold_number'],
          binding: [
            { header: this.$t('56_pattern.table_product_name'), binding: 'product_name' },
            { header: this.$t('56_pattern.table_remarks'), binding: 'memo_1', isInputText: true },
            { header: this.$t('56_pattern.table_times_use'), binding: 'times_use' },
            { header: this.$t('56_pattern.table_sold_number'), binding: 'sold_number' },
          ],
        },
        { scope: 3, category: 12, pattern_id: 1, cellInputInView: ['industrial_waste_value'] },
        { scope: 3, category: 13, pattern_id: 1, cellInputInView: ['scale_index_value'] },
        {
          scope: 3,
          category: 14,
          pattern_id: 1,
          cellInputInView: ['emissions'],
          binding: [{ header: this.$t('56_pattern.table_emissions_c14'), binding: 'emissions' }],
        },
      ];
      return patternsSupplier.find(
        (pattern) =>
          pattern.scope === Number(this.infoFormPrimaryData.scope) &&
          pattern.category === Number(this.infoFormPrimaryData.category) &&
          pattern.pattern_id === Number(this.infoFormPrimaryData.pattern_id),
      );
    },
    onHandleActionTable() {
      // can't submit data
      const dataSubmit = this.prepareDataSubmit();
      if (Object.values(dataSubmit).length <= 0) {
        this.alertNotify = true;
        this.messageNotification = this.$t('supplier.title_notify_send_data');
        return;
      }

      this.supplierGrid.getError = this.getError;
      let errorData = [];
      const { scope, category, pattern_id } = this.infoFormPrimaryData;

      let itemsInView = [...this.pattern.cellInputInView, 'evidence'];
      let itemTextRemove = '';
      if (scope == 3 && category == 6 && pattern_id == 4) {
        itemTextRemove = 'transportation_facilities';
      } else if (scope == 3 && category == 6 && pattern_id == 6) {
        itemTextRemove = 'business_trip_type';
      } else if (scope == 3 && category == 7 && pattern_id == 4) {
        itemTextRemove = 'transportation_facilities';
      }
      itemsInView = itemsInView.filter((item) => item !== itemTextRemove);

      this.supplierGrid.sourceCollection.forEach((item) => {
        if (itemsInView.every((field) => item[field] === null || item[field] === '')) {
          return true;
        }
        this.flexGrid.columns.forEach((column) => {
          errorData.push(this.getError(item, column.binding));
          return true;
        });
      });
      errorData = errorData.filter((item) => item !== null);
      if (errorData.length > 0) {
        if (errorData.includes(MESSAGE_NOT_NULL)) {
          this.alertNotify = true;
          this.messageNotification = this.$t('register_data.popup_message_require_input');
        }
      } else {
        this.dialogPopup = true; // pass all case and can submit data
      }
    },
    prepareDataSubmit() {
      const scope = parseInt(this.infoFormPrimaryData.scope);
      const category = parseInt(this.infoFormPrimaryData.category);
      const pattern_id = parseInt(this.infoFormPrimaryData.pattern_id);
      let editedBlank = {};
      let indexBlank = 0;
      let self = this;
      self.supplierGrid.items.forEach((record, indexWithBlankId) => {
        const cellInputInView = this.pattern.cellInputInView;
        const everyValueNotChange = cellInputInView.every((field) => {
          return record[field] === null || record[field] === '' || record[field] === undefined;
        });
        if (!everyValueNotChange || record.evidence) {
          const dataValuesMapping = {};
          const fieldsToIterate =
            scope === 3 && category === 15 && [5, 6, 7, 8, 9, 10, 11].includes(pattern_id)
              ? this.pattern.fieldsSubmit
              : this.pattern.cellInputInView;

          fieldsToIterate.forEach((binding) => {
            dataValuesMapping[binding] = formatValue(record[binding]);
          });
          let prepareData = {
            ...dataValuesMapping,
            evidence: record.evidence || null,
          };
          if (record.id === BLANK_ID) {
            editedBlank[indexWithBlankId] = prepareData;
            indexBlank += 1;
          }
        }
      });
      return editedBlank;
    },
    submitDataSupplier() {
      if (!this.infoFormPrimaryData.uuid) {
        this.$router.push({ path: ROUTES.SUPPLIER, query: { primary_uuid: this.$route?.query?.primary_uuid } });
      } else {
        const data = this.prepareDataSubmit();
        const dataSubmit = {
          action: 'submitPrimary',
          data: Object.values(data),
          dataWorkflow: {
            uuid_primary_data: this.infoFormPrimaryData.uuid,
            month: this.dataInfo.month,
            year: this.dataInfo.year,
            note: this.dataInfo.note,
            scope: this.infoFormPrimaryData.scope,
            category: this.infoFormPrimaryData.category,
            pattern_id: this.infoFormPrimaryData.pattern_id,
            type_classify: this.infoFormPrimaryData?.type_classify?.id,
            type_data: this.infoFormPrimaryData?.type_data?.id
          },
          check_duplicate: false,
        };
        Object.values(data).length
          ? submitDataSupplierAction(dataSubmit)
              .then((res) => {
                this.dialogPopup = false;
                this.setStatusDataSupplier(true);
                this.actionUpdateIsLoadingTable(false);
                this.$router.push({ path: ROUTES.SUPPLIER, query: { primary_uuid: this.$route?.query?.primary_uuid } });
              })
              .catch((error) => {
                if (!error.errors?.data_duplicate && getErrorMessge(error).length > 0) {
                  this.isReloadPage = false;
                  this.dialogDeleteDataApproval = true;
                  this.messageDialogDeleteDataApproval = getErrorMessge(error);
                }
                this.dialogPopup = false;
                this.actionUpdateIsLoadingTable(false);
              })
          : emptyPromise();
      }
    },
    getError(item, propName, s) {
      let itemsInView = this.pattern.cellInputInView;
      const { scope, category, pattern_id } = this.infoFormPrimaryData;
      let itemTextRemove = '';
      if (scope == 3 && category == 6 && pattern_id == 4) {
        if (!item?.transportation_facilities) {
          itemTextRemove = 'transportation_facilities';
        }
        // itemTextRemove = 'transportation_facilities';
      } else if (scope == 3 && category == 6 && pattern_id == 6) {
        // itemTextRemove = 'business_trip_type';
        if (!item?.business_trip_type) {
          itemTextRemove = 'business_trip_type';
        }
      } else if (scope == 3 && category == 7 && pattern_id == 4) {
        // itemTextRemove = 'transportation_facilities';
        if (!item?.transportation_facilities) {
          itemTextRemove = 'transportation_facilities';
        }
      } else if (scope == 3 && category == 11) {
        // itemTextRemove = 'transportation_facilities';
        if (!item?.memo_1) {
          itemTextRemove = 'memo_1';
        }
      } 
      itemsInView = itemsInView.filter((item) => item !== itemTextRemove);
      if (
        [...itemsInView, 'evidence'].every(
          (field) => item[field] === null || item[field] === '' || item[field] === undefined,
        )
      ) {
        return null;
      }

      if (this.max128Charactor.includes(propName)) {
        if (itemsInView.includes(propName) && this.emptyCell(item[propName])) {
          return this.emptyCell(item[propName]);
        }
        return validateMaximumCharactorOnlyFacility(String(item[propName]), 128, true);
      }

      if (itemsInView.includes(propName)) {
        return this.emptyCell(item[propName]);
      }
      return null;
    },
    emptyCell(item) {
      if (item === undefined || item === null || item === '') {
        return this.$t('validation.error_required_field');
      }
      return null;
    },
    async handleGetGicsList() {
      try {
        const gics = getListGicsByYear()
        this.gicsList = gics[this.dataInfo.year] // todo: update gicsList by this.dataInfo.year and response data
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.list-data-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.submit-register-btn {
  min-width: 75px !important;
}
@media (max-width: 480px) {
  .custom-table {
    padding-left: 20px !important;
  }
}
@media (min-width: 480px) {
  .wrap {
    .table-supplier {
      width: fit-content;
      max-width: 100%;
    }
  }
  .custom-table {
    padding-right: 0 !important;
  }
}
</style>
<style lang="scss">
.wj-flexgrid .wj-cell {
  padding: 4px !important;
  &.attach-file {
    &.btn-disabled {
      button {
        background: $monoLight !important;
        color: $monoBlack !important;
        border: unset;
      }
    }
    .wj-cell-maker {
      color: $goldMid !important;
      position: unset !important;
      background: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $goldLight;
      padding: 1px 5px 3px !important;
      margin: 0 auto;
      min-width: 38px;
      &:hover {
        background: #0072a3;
        border: unset;
        color: $monoWhite !important;
        .btn_custom {
          color: $monoWhite;
        }
      }
      &:has(.file-attached-container) {
        border: 1px solid $goldMid;
      }
    }
    &.wj-has-changed {
      button {
        color: $goldHeavy !important;
        border: 1px solid $goldHeavy;
      }
    }
    .file-attached-container {
      width: 18px;
      height: 18px;
      .file_attached {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
      }
    }
  }
}
.new-register-supplier {
  .wj-colheaders {
    .wj-row {
      .wj-cell {
        &.hide-filter {
          .wj-btn-glyph {
            display: none;
          }
        }
      }
    }
  }
}
</style>
